import React from "react";
import ChooseUsCard from "./ChooseUsCard";
import { Container, Row, Col } from "react-bootstrap";
import Line from "./Line";
import EasyProcess from "../images/easy-process-color.svg";
import time from "../images/time-color.svg";
import cost from "../images/cost-color.svg";
import simple from "../images/simple-color.svg";
import choice from "../images/choice-color.svg";
import AliceCarousel from "react-alice-carousel";

const responsive = {
  0: { items: 1 },
  768: { items: 2 },
  992: { items: 2 },
  1200: { items: 3 },
};


const ChooseUS = ({ getTranslatedText }) => {

  const items = [
    <ChooseUsCard image={EasyProcess} text={getTranslatedText("ChooseUs Process")} />,
    <ChooseUsCard image={time} text={getTranslatedText("ChooseUs Finance")} />,
    <ChooseUsCard image={cost} text={getTranslatedText("ChooseUs Rates")} />,
    <ChooseUsCard image={simple} text={getTranslatedText("ChooseUs Manual")} />,
    <ChooseUsCard image={choice} text={getTranslatedText("ChooseUs Shariah")} />,
  ];
  
  return (
    <section className="choose-us">
      <Container>
        <Row>
          <Col className="choose-us__col">
            <Line type="large" />
            <h2 className="choose-us__heading">
              {getTranslatedText("ChooseUs Heading")}
            </h2>
            <div className="choose-us__wrapper">
              <AliceCarousel
                infinite={true}
                autoPlay={true}
                items={items}
                responsive={responsive}
                animationDuration={4000}
                disableButtonsControls={true}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ChooseUS;

import React from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
import Line from "./Line";
import creditper from "../images/splashscreen.png";
// import { graphql, useStaticQuery } from 'gatsby';

// import Img from 'gatsby-image';

const AboutUs = ({ getTranslatedText }) => {
  // const data = useStaticQuery(
  //   graphql`
  //     query {
  //       desktop: file(relativePath: { eq: "laptop.PNG" }) {
  //         childImageSharp
  //           fluid(quality: 100, maxWidth: 612, maxHeight: 340) {
  //             ...GatsbyImageSharpFluid
  //           }
  //         }
  //       }
  //     }
  //   `
  // );

  // const laptop = data.desktop.childImageSharp.fluid;
  return (
    <section id="about" className="about">
      <Container>
        <Row>
          <Col className="about__col-left" md={12} lg={6} xl={6}>
            <Image src={creditper} fluid={true} className="about__image" />
          </Col>
          <Col className="about__col-right" md={12} lg={6} xl={6}>
            <Line type="large" />
            <h2 className="about__heading">
              {getTranslatedText("AboutSection Heading")}
            </h2>
            <p className="about__text">
              {getTranslatedText("AboutSection description")}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;

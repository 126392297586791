import React from 'react';
import Line from './Line';
import { Container, Row, Col } from 'react-bootstrap';
import bike from '../images/bike-color.svg';
import macbook from '../images/macbook-color.svg';
import phone from '../images/iphone-color.svg';
import EmployeeCard from './EmployeeCard';

const EmployeeFinancing = ({getTranslatedText}) => {
  return (
    <div className='employee'>
      <Container>
        <Row>
          <Col className='employee__upper-col'>
            <Line type='large' />
              
            <h2 className='employee__heading'>
            {getTranslatedText("EmployeeFinancing Heading")}
            </h2>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={6} xl={6} className='employee__lower-col-left'>
            <EmployeeCard
              image={bike}
              title= {getTranslatedText("EmployeeFinancing Bikes")}
              text={getTranslatedText("EmployeeFinancing BikesDescp")}
            />
          </Col>
          <Col lg={6} xl={6} className='employee__lower-col-right'>
            <EmployeeCard
              image={macbook}
              title= {getTranslatedText("EmployeeFinancing Laptop")}
              text={getTranslatedText("EmployeeFinancing LaptopDescp")}
            />
          </Col>
          <Col className='employee__lower-col-mid'>
            <EmployeeCard
              image={phone}
              title= {getTranslatedText("EmployeeFinancing Mobile")}
              text={getTranslatedText("EmployeeFinancing MobileDescp")}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EmployeeFinancing;
